import { useGetCompanies } from "features/companies/hooks/useGetCompanies";
import { SimpleCompanyDef } from "features/companies/types/companies.types";
import { useGetCraftsmen } from "features/craftsmen/hooks/useGetCraftsmen";
import { useGetJobAds } from "features/job-ads/hooks/useGetJobAds";
import { useEffect, useState } from "react";
import { CraftsmanProfileDef } from "types/crafthunt-profile.types";
import { JobAdDef } from "types/job-ad.types";
import { removeWhitespace } from "utils/helpers";
import { fetchGeoData } from "utils/services/google-ip-geolocation.service";
import { EShowcaseType } from "./Showcase";

type GetShowcaseItemsProps = {
  type?: EShowcaseType;
  limit: number;
  lang: string;
  isUseVisitorsLocation?: boolean;
  coordinates?: string;
  radius?: number;
  trades?: string;
  roles?: string;
  onlyShowVerifiedCraftsmen?: boolean;
};

const cleanFilters = (str: string) => {
  if (!str || !str.trim()) {
    return undefined;
  }
  return removeWhitespace(str.toLowerCase().replace(/\"/g, "")).split(",");
};

export function useGetShowcaseItems({
  type,
  limit,
  lang,
  isUseVisitorsLocation,
  coordinates,
  radius,
  trades,
  roles,
  onlyShowVerifiedCraftsmen,
}: GetShowcaseItemsProps): {
  items: JobAdDef[] | SimpleCompanyDef[] | CraftsmanProfileDef[];
  isLoading: boolean;
} {
  const [visitorCoordinates, setVisitorCoordinates] = useState<
    string | undefined
  >(undefined);
  const [isFetchingGeoData, setIsFetchingGeoData] = useState(
    isUseVisitorsLocation
  );

  const cleanCoordinates = removeWhitespace(visitorCoordinates ?? coordinates);
  const cleanRadius = visitorCoordinates ? undefined : radius;
  const cleanTrades = cleanFilters(trades);
  const cleanRoles = cleanFilters(roles)?.map((role) =>
    role.endsWith("-role") ? role : `${role}-role`
  );

  // Fetch visitor's geo data if isUseVisitorsLocation is true
  useEffect(() => {
    if (isUseVisitorsLocation) {
      const fetchLocation = async () => {
        setIsFetchingGeoData(true);
        try {
          const { lat, lng } = await fetchGeoData({
            shouldNotReverseGeocode: true,
          });
          setVisitorCoordinates(`${lat},${lng}`);
        } catch (error) {
          console.error("Failed to fetch geo data", error);
        } finally {
          setIsFetchingGeoData(false);
        }
      };

      fetchLocation();
    }
  }, [isUseVisitorsLocation]);

  // Get jobs
  const { jobAds, isLoading: jobsIsLoading } = useGetJobAds({
    shouldFetch: !isFetchingGeoData && type === EShowcaseType.JOBS,
    limit,
    lang,
    coordinates: cleanCoordinates,
    radius: cleanRadius,
    trades: cleanTrades,
    jobTitles: cleanRoles,
    shuffleList: "true",
  });
  // Get companies
  const { companies, isLoading: companiesIsLoading } = useGetCompanies({
    shouldFetch: !isFetchingGeoData && type === EShowcaseType.COMPANIES,
    limit,
    lang,
    coordinates: cleanCoordinates ? `["${cleanCoordinates}"]` : undefined,
    radius: cleanRadius,
    companyTrades: cleanTrades?.length
      ? `[${cleanTrades.map((trade) => `"${trade}"`)}]`
      : undefined,
    simpleSearch: "true",
    shuffleList: "true",
  });
  // Get craftsmen
  const { craftsmen, isLoading: craftsmenIsLoading } = useGetCraftsmen({
    shouldFetch: !isFetchingGeoData && type === EShowcaseType.CRAFTSMEN,
    limit,
    lang,
    coordinates: cleanCoordinates ? `["${cleanCoordinates}"]` : undefined,
    radius: cleanRadius,
    projectTrades: cleanTrades?.length
      ? `[${cleanTrades.map((trade) => `"${trade}"`)}]`
      : undefined,
    jobTitles: cleanRoles?.length
      ? `[${cleanRoles.map((trade) => `"${trade}"`)}]`
      : undefined,
    shuffleList: "true",
    onlyVerified: onlyShowVerifiedCraftsmen ? "true" : undefined,
  });

  if (type === EShowcaseType.JOBS) {
    return {
      items: jobAds,
      isLoading: jobsIsLoading || isFetchingGeoData,
    };
  }
  if (type === EShowcaseType.COMPANIES) {
    return {
      items: companies,
      isLoading: companiesIsLoading || isFetchingGeoData,
    };
  }
  return {
    items: craftsmen,
    isLoading: craftsmenIsLoading || isFetchingGeoData,
  };
}
