import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { RichText } from "@crafthunt-ui/RichText/RichText";
import clsx from "clsx";
import { ScreenSizes } from "constants/breakpoints";
import { EColors } from "constants/colors.constants";
import { CSSProperties } from "react";
import { StoryblokSettingsDef } from "types/storyblok.types";
import styles from "./Text.module.scss";

type TextProps = {
  blok: {
    type?: "markdown" | "richtext";
    markdown?: string;
    richtext?: object;
    backgroundColor?: EColors;
  };
  settings?: StoryblokSettingsDef;
  locale: string;
  className?: string;
  style?: CSSProperties;
};

export const Text = ({
  blok,
  locale,
  settings,
  className,
  style,
}: TextProps) => {
  const bgColorVariants = {
    [EColors.WHITE]: "bg-white text-gray-500",
    [EColors.GRAY]: "bg-gray-100 text-black",
    [EColors.DARK_BLUE]: "bg-gray-900 text-white",
    [EColors.AMBER]: "bg-primary-500 !text-white",
  };
  const textColorVariants = {
    [EColors.WHITE]: "",
    [EColors.GRAY]: "",
    [EColors.DARK_BLUE]: "dark:prose-invert",
    [EColors.AMBER]: styles.whiteText,
  };

  return (
    <div
      className={clsx(
        "relative py-8",
        className,
        bgColorVariants[blok.backgroundColor || EColors.WHITE]
      )}
      style={style}
    >
      <div
        className={clsx(
          "relative mx-auto px-8 mt-6 prose prose-primary prose-lg whitespace-pre-line",
          textColorVariants[blok.backgroundColor || EColors.WHITE]
        )}
        style={{ maxWidth: ScreenSizes.MEDIUM }}
      >
        {blok.type === "richtext" ? (
          <div className="relative px-8 max-w-none">
            <RichText
              content={blok.richtext}
              maxWidth={ScreenSizes.MEDIUM}
              locale={locale}
              settings={settings}
            />
          </div>
        ) : (
          <Markdown>{blok.markdown}</Markdown>
        )}
      </div>
    </div>
  );
};
