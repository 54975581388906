import { Transition } from "@headlessui/react";
import { ReactNode } from "react";

type AuthInputTransitionProps = {
  show: boolean;
  className?: string;
  children: ReactNode;
};

export const AuthInputTransition = ({
  show,
  className,
  children,
}: AuthInputTransitionProps) => {
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      className={className}
    >
      {children}
    </Transition>
  );
};
