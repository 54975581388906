import { fetcher, put } from "constants/api";
import {
  CrafthuntStatDefRequest,
  CrafthuntStatResponseDef,
} from "types/crafthunt-profile.types";

export const updateStats = (id: string, data: CrafthuntStatDefRequest) => {
  return put<CrafthuntStatResponseDef>(`/crafthunts/stats/${id}`, data);
};

export const fetchStats = () => {
  return fetcher<CrafthuntStatResponseDef>(`/crafthunts/stats`);
};
