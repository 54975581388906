import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { StoryblokComponent } from "@storyblok/react";
import clsx from "clsx";
import { DynamicComponentNames } from "constants/dynamic-components.constants";
import { CSSProperties } from "react";
import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";
import styles from "./RichText.module.scss";

type RichTextProps = {
  content: unknown;
  maxWidth?: number;
  locale: string;
  settings?: StoryblokSettingsDef;
};

const ALLOW_PROSE: DynamicComponentNames[] = ["Text", "featuretailwind"];
const DISABLE_FULL_WIDTH: DynamicComponentNames[] = ["RichTextContent"];

export const RichText = ({
  content,
  maxWidth,
  locale,
  settings,
}: RichTextProps) => {
  return (
    <div className={styles.richtext}>
      {render(content, {
        markResolvers: {
          [MARK_LINK]: (children, { uuid, href, target, linktype }) => {
            if (linktype === "email") {
              return (
                <a id={uuid} href={`mailto:${href}`}>
                  {children}
                </a>
              );
            }
            if (target === "_self" && href.charAt(0) === "#") {
              return (
                <a id={uuid} href={href} target={target}>
                  {children}
                </a>
              );
            }
            return (
              <CustomLink
                id={uuid}
                href={getTranslatedSlug(href)}
                target={target}
              >
                {children}
              </CustomLink>
            );
          },
        },
        defaultBlokResolver: (name, props) => {
          const blok = { ...props, component: name };
          const disableProse = !ALLOW_PROSE.includes(
            name as DynamicComponentNames
          );
          const isFullWidth = !DISABLE_FULL_WIDTH.includes(
            name as DynamicComponentNames
          );

          return (
            <StoryblokComponent
              blok={blok}
              settings={settings}
              key={props._uid}
              locale={locale}
              className={clsx(
                disableProse && "not-prose",
                isFullWidth && styles.fullWidthContainer
              )}
              style={
                {
                  "--blog-max-width": isFullWidth ? `${maxWidth}px` : undefined,
                } as CSSProperties
              }
            />
          );
        },
      })}
    </div>
  );
};
