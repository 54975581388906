import { Country, isSupportedCountry } from "react-phone-number-input";

export const convertLocaleForBackend = (locale?: string) => {
  return (locale || "de").replace("_", "-").toLowerCase();
};

export const convertLocaleForAnalytics = (locale?: string) => {
  const [first, second] = (locale || "de").replace("_", "-").split("-");
  return [first.toLowerCase(), second?.toUpperCase()].filter(Boolean).join("-");
};

export const mapLocaleToPhoneInputCountry = (
  locale: string
): Country | null => {
  switch (locale.toLowerCase()) {
    case "en":
      return "GB";
    case "de":
      return "DE";
    case "pt-br":
      return "BR";
    case "el":
      return "GR";
    case "cs":
      return "CZ";
    case "da":
      return "DK";
    default:
      if (isSupportedCountry(locale.toUpperCase() as Country)) {
        return locale.toUpperCase() as Country;
      }
      return null;
  }
};

export const getDefaultPhoneInputCountry = (locale: string): Country => {
  const country = mapLocaleToPhoneInputCountry(locale);
  if (!country) {
    return "DE";
  }
  switch (country) {
    case "GB":
      return "DE";
    default:
      return country;
  }
};
