import clsx from "clsx";
import NextImage from "next/image";
import { useRouter } from "next/router";
import {
  AppStoreCS,
  AppStoreDA,
  AppStoreDE,
  AppStoreEL,
  AppStoreEN,
  AppStoreES,
  AppStoreFR,
  AppStoreIT,
  AppStorePL,
  AppStoreRO,
} from "../../icons/appstore/AppStore";
import styles from "./AppDownloadLink.module.scss";

const getAppStoreIcon = (lang: string) => {
  switch (lang.toUpperCase()) {
    case "CS":
      return <AppStoreCS />;
    case "DA":
      return <AppStoreDA />;
    case "DE":
      return <AppStoreDE />;
    case "EL":
      return <AppStoreEL />;
    case "ES":
      return <AppStoreES />;
    case "FR":
      return <AppStoreFR />;
    case "IT":
      return <AppStoreIT />;
    case "PL":
      return <AppStorePL />;
    case "RO":
      return <AppStoreRO />;
    case "EN":
    default:
      return <AppStoreEN />;
  }
};

const AppDownloadLink = () => {
  const router = useRouter();

  return (
    <div className="flex flex-wrap">
      <a
        className={styles.appStoreLink}
        href="https://apps.apple.com/app/id1613904721"
        target="_blank"
        rel="noreferrer"
      >
        {getAppStoreIcon(router.locale)}
      </a>
      <a
        className={clsx(styles.googleLink, "block relative")}
        href="https://play.google.com/store/apps/details?id=app.crafthunt.crafthunt"
        target="_blank"
        rel="noreferrer"
      >
        <NextImage
          src={`https://play.google.com/intl/en_us/badges/static/images/badges/${router.locale}_badge_web_generic.png`}
          width={128}
          height={50}
          sizes="(max-width: 767px) 20vw, (max-width: 1023px) 15vw, 10vw"
          style={{
            width: "100%",
            height: "auto",
          }}
          alt="Google Play Store"
        />
      </a>
    </div>
  );
};
export default AppDownloadLink;
