import { storyblokEditable } from "@storyblok/react";
import Script from "next/script";

const HubspotForm = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={`hubspot-form-${blok._uid}`}>
      <Script id={blok.hubspotFormId}>
        {`hbspt.forms.create({
                portalId: '2463807',
                formId: '${blok.hubspotFormId}',
            })`}
      </Script>
    </div>
  );
};

export default HubspotForm;
