import { AuthCTAButton } from "@auth/components/AuthCTAButton/AuthCTAButton";
import { AuthCTAButtonDef } from "@auth/types/auth-cta-button.types";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import { EColors } from "constants/colors.constants";
import { EJourneyTypes } from "constants/journey.constants";
import { CSSProperties } from "react";
import { StoryblokLinkDef } from "types/storyblok.types";
import { getTranslatedSlug } from "utils/router.helper";

type CallToActionBannerProps = {
  blok: {
    _uid: string;
    header?: string;
    subheader?: string;
    text?: string;
    backgroundColor?: EColors;
    withRoundBorder?: boolean;
    withBoxShadow?: boolean;
    withCompactSpacing?: boolean;

    cta?: AuthCTAButtonDef[];
    buttonLabel?: string;
    buttonJourney?: EJourneyTypes;
    button_link?: StoryblokLinkDef;

    textButtonLabel?: string;
    textButtonJourney?: EJourneyTypes;
    text_button_link?: StoryblokLinkDef;
  };
  className?: string;
  style?: CSSProperties;
};

const CallToActionBanner = ({
  blok,
  className,
  style,
}: CallToActionBannerProps) => {
  const bgColorVariants = {
    [EColors.WHITE]: "bg-white text-black",
    [EColors.GRAY]: "bg-gray-100 text-black",
    [EColors.DARK_BLUE]: "bg-gray-900 text-white",
    [EColors.AMBER]: "bg-primary-500 text-white",
  };
  const primaryCTAColorVariants = {
    [EColors.WHITE]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.GRAY]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.DARK_BLUE]: "bg-primary-600 hover:bg-primary-700 text-white",
    [EColors.AMBER]: "bg-white text-primary-600",
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        bgColorVariants[blok.backgroundColor || EColors.DARK_BLUE],
        blok.withRoundBorder && "rounded-lg",
        blok.withBoxShadow && "border shadow-lg",
        className
      )}
      style={style}
    >
      <div
        className={clsx(
          "mx-auto max-w-7xl px-6 lg:px-8 lg:flex lg:items-center lg:justify-between gap-8",
          blok.withCompactSpacing ? "py-6 lg:py-8" : "py-16 sm:py-20"
        )}
      >
        <div className="space-y-2">
          {(!!blok.header || !!blok.subheader) && (
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              {blok.header}
              {!!blok.header && !!blok.subheader && <br />}
              {blok.subheader}
            </h2>
          )}
          {!!blok.text && <div className="text-xl">{blok.text}</div>}
        </div>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
          {blok.cta?.map((ctaBlok) => (
            <AuthCTAButton key={ctaBlok._uid} blok={ctaBlok} />
          ))}
          {!!blok.buttonLabel && (
            <CustomLink
              isCTA
              id={`cta-banner-button-${blok._uid}`}
              href={getTranslatedSlug(
                blok.button_link,
                { fromJourney: blok.buttonJourney },
                "https://admin.crafthunt.app/signup"
              )}
              target={blok.button_link?.target}
              className={clsx(
                "rounded-sm px-5 py-4 text-lg font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600",
                primaryCTAColorVariants[
                  blok.backgroundColor || EColors.DARK_BLUE
                ]
              )}
            >
              {blok.buttonLabel}
            </CustomLink>
          )}
          {!!blok.textButtonLabel && (
            <CustomLink
              isCTA
              id={`cta-banner-text-button-${blok._uid}`}
              href={getTranslatedSlug(
                blok.text_button_link,
                {
                  fromJourney: blok.textButtonJourney,
                },
                "https://link.crafthunt.app/termin"
              )}
              target={blok.text_button_link?.target}
              className="text-sm font-semibold leading-6 hover:underline"
            >
              {blok.textButtonLabel} <span aria-hidden="true">→</span>
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallToActionBanner;
