import { CertificateRequest } from "@certificates/types/certificates.types";
import { deletes, post, put } from "constants/api";
import { CrafthuntCertificateDef } from "types/crafthunt-profile.types";

export const createCertificate = (data: CertificateRequest) => {
  return post<CrafthuntCertificateDef>("/profile_certificates", data);
};

export const updateCertificate = (id: string, data: CertificateRequest) => {
  return put<CrafthuntCertificateDef>(`/profile_certificates/${id}`, data);
};

export const deleteCertificate = (id: string) => {
  return deletes<CrafthuntCertificateDef>(`/profile_certificates/${id}`);
};
