import { CreateEditMediaDef, EDocumentType } from "@auth/types/auth.types";
import { UploadedMedia } from "@file-uploader/types/media.types";
import {
  CreateEditExternalLinkDef,
  EExternalLink,
} from "types/external-links.types";

export const uploadAndMapDocumentsToMedia = async (
  documents?: UploadedMedia[]
) => {
  const medias: CreateEditMediaDef[] = [];
  if (documents?.length) {
    for (const [index, doc] of documents.entries()) {
      try {
        medias.push({
          filename: doc.fileName,
          url: doc.url,
          type: EDocumentType.CV,
          order: index,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
  return medias;
};

export const mapSocialLinkToExternalLinks = (socialLink?: string) => {
  const externalLinks: CreateEditExternalLinkDef[] = [];
  const trimSocialLink = socialLink?.trim();
  if (trimSocialLink) {
    let type = EExternalLink.DOMAIN;
    if (trimSocialLink.includes("xing")) {
      type = EExternalLink.XING;
    } else if (trimSocialLink.includes("facebook")) {
      type = EExternalLink.FACEBOOK;
    } else if (trimSocialLink.includes("instagram")) {
      type = EExternalLink.INSTAGRAM;
    } else if (trimSocialLink.includes("tiktok")) {
      type = EExternalLink.TIKTOK;
    } else if (trimSocialLink.includes("linked")) {
      type = EExternalLink.LINKEDIN;
    }
    externalLinks.push({
      value: trimSocialLink,
      type,
    });
  }
  return externalLinks;
};
