import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";

const LogoCloud = ({ blok, className = undefined, style = undefined }) => (
  <div className={clsx("bg-white", className)} style={style}>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
        {blok.logos.map((item) => (
          <a
            key={item.name}
            href={getTranslatedSlug(item.url)}
            className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 hover:shadow-md h-10 relative"
          >
            <NextImage
              src={transformImage(
                item.image.filename,
                "300x0/filters:quality(80)"
              )}
              alt={item.name}
              fill
              sizes="(max-width: 767px) 50vw, (max-width: 1023px) 25vw, 20vw"
            />
          </a>
        ))}
      </div>
    </div>
  </div>
);

export default LogoCloud;
