import {
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import {
  FormikField,
  FormikFieldProps,
} from "components/forms/generic/FormikField";
import { useState } from "react";

type FormikPasswordFieldProps = Omit<
  FormikFieldProps,
  "type" | "icon" | "iconAfter"
>;

export const FormikPasswordField = ({
  placeholder = "********",
  ...rest
}: FormikPasswordFieldProps) => {
  const [showPasswordInClearText, setShowPasswordInClearText] = useState(false);

  const EyeToggle = ({ showText, ...props }) => {
    return showText ? <EyeIcon {...props} /> : <EyeSlashIcon {...props} />;
  };

  return (
    <FormikField
      type={showPasswordInClearText ? "text" : "password"}
      placeholder={placeholder}
      icon={LockClosedIcon}
      iconAfter={
        <EyeToggle
          showText={showPasswordInClearText}
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
          onClick={() => setShowPasswordInClearText((prevState) => !prevState)}
        />
      }
      {...rest}
    />
  );
};
