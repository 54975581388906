import { BlogCard } from "@crafthunt-ui/BlogCard";
import { ImageFallbackEnum } from "@crafthunt-ui/Image";
import { Loading } from "@crafthunt-ui/Loading/Loading";
import {
  EVerifiedBadgeSize,
  EVerifiedBadgeType,
  VerifiedBadge,
} from "@crafthunt-ui/VerifiedBadge/VerifiedBadge";
import { useTranslation } from "next-i18next";
import { ShowcaseItemDef } from "../../Showcase";

type ShowcaseItemProps = {
  item?: ShowcaseItemDef;
  isLoading?: boolean;
  showButton?: boolean;
  onClick?: () => void;
};
const CARD_CLASSES = "basis-full lg:basis-1/2";

export const ShowcaseItem = ({
  item,
  isLoading,
  showButton,
  onClick,
}: ShowcaseItemProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton />;
  }

  if (!item) {
    return null;
  }

  // Job Ad
  if ("crafthuntCompany" in item) {
    return (
      <BlogCard
        className={CARD_CLASSES}
        key={item.id}
        postImage={item.coverImage || item.crafthuntCompany.coverImage}
        postImageFallbackType={ImageFallbackEnum.COMPANY}
        postTitle={item.title}
        postPreview={item.description}
        postLink={`/${item.fullSlug}`}
        category={item.crafthuntCompany?.name}
        showButton={showButton}
        onClick={onClick}
      />
    );
  }

  // Craftsman
  if ("firstName" in item) {
    const coverImage =
      item.jobTitle?.imageUrl ||
      (item.profileTrades?.length && item.profileTrades[0].imageUrl);
    const jobTitle = item.jobTitle && t(item.jobTitle.name);
    const profileTrades =
      item.profileTrades?.length &&
      item.profileTrades
        .slice(0, 3)
        .map((trade) => t(trade.name))
        .join(", ");
    const isAnonymous =
      typeof item.isViewingAnonymousSlug === "boolean"
        ? item.isViewingAnonymousSlug
        : item.isAnonymous;

    return (
      <BlogCard
        className={CARD_CLASSES}
        key={item.id}
        postImage={coverImage}
        postImageFallbackType={ImageFallbackEnum.CRAFTSMAN}
        postTitle={
          <>
            {isAnonymous
              ? jobTitle || t("chat-name-anonymous-user")
              : [item.firstName, item.lastName].filter(Boolean).join(", ")}
            {item.isVerified && (
              <VerifiedBadge
                type={EVerifiedBadgeType.NAME}
                size={EVerifiedBadgeSize.MEDIUM}
              />
            )}
          </>
        }
        postPreview={item.description}
        postLink={`/craftsmen/${isAnonymous ? item.anonymousSlug : item.slug}`}
        category={isAnonymous ? profileTrades : jobTitle || profileTrades}
        showButton={showButton}
        onClick={onClick}
      />
    );
  }

  // Company
  return (
    <BlogCard
      className={CARD_CLASSES}
      key={item.id}
      postImage={item.coverImage}
      postImageFallbackType={ImageFallbackEnum.COMPANY}
      postTitle={item.name}
      postPreview={"about" in item ? item.about : item.description}
      postLink={`/companies/${item.slug}`}
      category={[item.address.city, item.address.country].join(", ")}
      showButton={showButton}
      onClick={onClick}
    />
  );
};

const Skeleton = () => {
  return (
    <Loading isSkeleton className={CARD_CLASSES}>
      <div className="aspect-w-3 aspect-h-2 bg-gray-200" />
      <div className="absolute left-0 top-0 h-48 bg-gray-300 w-full" />
      <div className="absolute left-6 top-52 h-7 bg-gray-300 rounded-full w-8/12 my-1.5 mr-2 px-3 py-0.5" />
      <div className="absolute left-6 top-64 h-4 bg-gray-300 rounded-full w-10/12 my-1.5 mr-2 px-3 py-0.5" />
      <div className="absolute left-6 top-72 h-4 bg-gray-300 rounded-full w-10/12 my-1.5 mr-2 px-3 py-0.5" />
      <div className="absolute left-6 top-80 h-4 bg-gray-300 rounded-full w-10/12 my-1.5 mr-2 px-3 py-0.5" />
    </Loading>
  );
};
