import clsx from "clsx";
import { LANGUAGES_PHONE } from "constants/languages";
import { useRouter } from "next/router";
import PhoneInput, {
  DefaultInputComponentProps,
  Value,
} from "react-phone-number-input";
import cz from "react-phone-number-input/locale/cz.json";
import de from "react-phone-number-input/locale/de.json";
import es from "react-phone-number-input/locale/es.json";
import fr from "react-phone-number-input/locale/fr.json";
import it from "react-phone-number-input/locale/it.json";
import pl from "react-phone-number-input/locale/pl.json";
import ptBR from "react-phone-number-input/locale/pt-BR.json";
import "react-phone-number-input/style.css";
import { getDefaultPhoneInputCountry } from "utils/language.helper";
import styles from "./PhoneNumberInput.module.scss";

export type PhoneNumberInputProps = {
  value?: string;
  onChange?: (value: Value) => void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  autoComplete?: string;
  autoFocus?: boolean;
} & DefaultInputComponentProps;

export const PhoneNumberInput = ({
  value,
  onChange,
  placeholder,
  className,
  inputClassName,
  autoComplete,
  autoFocus,
  ...rest
}: PhoneNumberInputProps) => {
  const { locale } = useRouter();
  const handleOnChange = (newValue: Value) => {
    onChange?.(newValue);
  };

  const getLabels = () => {
    switch (locale.toLowerCase()) {
      case "de":
        return de;
      case "pt_br":
        return ptBR;
      case "pl":
        return pl;
      case "it":
        return it;
      case "es":
        return es;
      case "cs":
        return cz;
      case "fr":
        return fr;
      default:
        return undefined;
    }
  };

  return (
    <PhoneInput
      defaultCountry={getDefaultPhoneInputCountry(locale)}
      countries={LANGUAGES_PHONE}
      labels={getLabels()}
      international
      countryCallingCodeEditable={false}
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
      className={clsx(styles.container, className)}
      numberInputProps={{
        className: clsx("py-2 pl-1.5 pr-3 text-base border", inputClassName),
        autoComplete,
        autoFocus,
      }}
      {...rest}
    />
  );
};
