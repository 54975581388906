import { functionsSetSession } from "@auth-session/api/auth.serverless.api";
import {
  ACCESS_TOKEN_KEY,
  AUTH_STATUS_KEY,
  AUTH_STORAGE_TYPE,
  USER_KEY,
} from "@auth/constants/auth-context.constants";
import {
  AUTH_SIGN_UP_FORM_KEY,
  AUTH_SIGN_UP_STEP_KEY,
} from "@auth/constants/auth-sign-up.form.constants";
import { useGetCompany } from "@companies/hooks/useGetCompany";
import { CompanyDef } from "@companies/types/companies.types";
import { PREFIX_APPLY_JOB_LAST_UPDATED_KEY } from "@job-application/constants/apply-to-job-form.constants";
import { removeFormQuestionAnswers } from "@job-questions/helpers/job-questions.helper";
import { USER_INFO_STEP_KEY } from "@profile/components/UserInfoForm/constants/user-info-form.constants";
import { removeFormUserInfo } from "@profile/components/UserInfoForm/helpers/user-info-form.helper";
import { useMount } from "ahooks";
import axios from "axios";
import { setAuthTokenForApiInstance } from "constants/api";
import { RoutesEnum } from "constants/routes.constants";
import { getStorage, removeStorage } from "context/context.helper";
import { useOnTabFocus } from "hooks/useOnTabFocus";
import { useStateWithStorage } from "hooks/useStateWithStorage";
import { useTranslation } from "next-i18next";
import Router, { useRouter } from "next/router";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import toast from "react-hot-toast";
import { resetAnalytics, triggerIdentify } from "utils/analytics";
import { getUserById, logout } from "../api/auth.api";
import { isAuthRoute, parseJwt } from "../helpers/auth.helper";
import { AuthUserDef, EAuthStatus, TokenPayloadDef } from "../types/auth.types";

type AuthContextDef = {
  isLoggedIn?: boolean;
  loading?: boolean;
  accessToken?: string;
  user?: AuthUserDef;
  userCompany?: CompanyDef;
  loadingUserCompany?: boolean;
  parsedToken?: TokenPayloadDef;
  hasAlreadyCompletedAtLeastOneMandatoryStep?: boolean;
  logoutAction: (skipBackendLogout?: boolean) => void;
  loginAction: (user: AuthUserDef, accessToken?: string) => void;
};

const AuthContext = createContext<AuthContextDef | null>(null);

type AuthProviderProps = {
  children: ReactNode;
  initialAccessToken?: string;
};

export function AuthProvider({
  children,
  initialAccessToken,
}: AuthProviderProps) {
  const router = useRouter();
  const [accessToken, setAccessToken] = useStateWithStorage<string>(
    ACCESS_TOKEN_KEY,
    AUTH_STORAGE_TYPE,
    initialAccessToken
  );
  const parsedToken = accessToken ? parseJwt(accessToken) : undefined;
  const [user, setUser] = useStateWithStorage<AuthUserDef>(
    USER_KEY,
    AUTH_STORAGE_TYPE
  );
  const [status, setStatus] = useStateWithStorage<EAuthStatus>(
    AUTH_STATUS_KEY,
    AUTH_STORAGE_TYPE,
    accessToken ? EAuthStatus.LOADING : EAuthStatus.NO_USER
  );
  /** If the user is a company admin, then we can fetch their company */
  const { company: userCompany, isLoading: loadingUserCompany } = useGetCompany(
    {
      idOrSlug: parsedToken?.company?.id,
      lang: router.locale,
      withJwt: true,
    }
  );
  const { t } = useTranslation();
  const hasAlreadyCompletedAtLeastOneMandatoryStep =
    !!user?.jobTitle?.id ||
    !!user?.profileTrades?.length ||
    !!user?.geoSearchAddress?.country;

  if (accessToken) {
    setAuthTokenForApiInstance(accessToken);
  } else {
    setAuthTokenForApiInstance(null);
  }

  // LOGIN ACTION
  const loginAction = useCallback(
    (user: AuthUserDef, accessToken?: string) => {
      triggerIdentify(user.id, {
        email: user.email,
        phone: user.phone,
      });
      setStatus(EAuthStatus.AUTHENTICATED);
      setUser(user);
      if (accessToken) {
        setAccessToken(accessToken);
        setAuthTokenForApiInstance(accessToken);
      }
    },
    [setAccessToken, setStatus, setUser]
  );

  const resetEverything = useCallback(() => {
    resetAnalytics();
    setStatus(EAuthStatus.NO_USER);
    setAccessToken(undefined);
    setAuthTokenForApiInstance(null);
    setUser(undefined);

    // Remove data from forms in local and session storage
    removeFormUserInfo();
    removeStorage(USER_INFO_STEP_KEY, "localStorage");
    removeStorage(AUTH_SIGN_UP_FORM_KEY, "localStorage");
    removeStorage(AUTH_SIGN_UP_STEP_KEY, "localStorage");
    removeFormQuestionAnswers();
    try {
      for (const key in window.localStorage) {
        if (key.includes(PREFIX_APPLY_JOB_LAST_UPDATED_KEY)) {
          removeStorage(key, "localStorage");
        }
      }
    } catch (error) {
      console.error(error);
    }
    if (isAuthRoute(router.pathname)) {
      Router.replace(RoutesEnum.FRONT_PAGE);
    }
  }, [router.pathname, setAccessToken, setStatus, setUser]);

  // LOGOUT ACTION
  const logoutAction = useCallback(
    async (skipBackendLogout = false) => {
      try {
        await functionsSetSession(null);
      } catch (err) {
        console.error(
          "could not call serverless function to clear session",
          err
        );
      }
      try {
        if (!skipBackendLogout) {
          await logout();
        }
      } finally {
        resetEverything();
      }
    },
    [resetEverything]
  );

  // CONTEXT
  const contextValue = useMemo(
    () => ({
      isLoggedIn: status === EAuthStatus.AUTHENTICATED,
      loading: status === EAuthStatus.LOADING,
      accessToken,
      user,
      userCompany,
      loadingUserCompany,
      parsedToken,
      hasAlreadyCompletedAtLeastOneMandatoryStep,
      logoutAction,
      loginAction,
    }),
    [
      status,
      accessToken,
      user,
      userCompany,
      loadingUserCompany,
      parsedToken,
      hasAlreadyCompletedAtLeastOneMandatoryStep,
      logoutAction,
      loginAction,
    ]
  );

  // HOOKS
  useMount(() => {
    const getUser = async () => {
      try {
        const responseUser = await getUserById(parsedToken.id);
        functionsSetSession({ accessToken });
        loginAction(responseUser);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response.status === 401) {
          logoutAction();
        }
      }
    };

    if (accessToken) {
      getUser();
    }
  });

  const onFocus = useCallback(() => {
    const localAccessToken = getStorage<string>(
      ACCESS_TOKEN_KEY,
      AUTH_STORAGE_TYPE
    );
    const localUser = getStorage<AuthUserDef>(USER_KEY, AUTH_STORAGE_TYPE);

    const loggedInFromAnotherTab =
      !accessToken && localAccessToken && localUser;
    const loggedInFromAnotherTabButDifferentUser =
      accessToken && localAccessToken && accessToken !== localAccessToken;
    const loggedOutFromAnotherTab = accessToken && !localAccessToken;

    if (loggedInFromAnotherTab) {
      toast.success(t("You have been logged in from another tab"));
      loginAction(localUser, localAccessToken);
    } else if (loggedInFromAnotherTabButDifferentUser) {
      toast.success(
        t("Another user logged in from another tab, current user logged out")
      );
      resetEverything();
      loginAction(localUser, localAccessToken);
    } else if (loggedOutFromAnotherTab) {
      toast.error(t("You have been logged out from another tab"));
      resetEverything();
    }
  }, [accessToken, loginAction, resetEverything, t]);

  useOnTabFocus(onFocus);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext) as AuthContextDef;
}
