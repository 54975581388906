import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import clsx from "clsx";
import NextImage from "next/image";
import { transformImage } from "utils/image.helper";

const LandFeatureScreenshot = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  return (
    <div
      className={clsx(
        "relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32 pb-4 lg:pb-12",
        className
      )}
      style={style}
    >
      <div className="relative mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
        <div>
          <SectionHeader
            label={blok.title}
            description={blok.description}
            content={blok.content}
          />
        </div>
        <div className="relative mt-12 h-80 lg:h-96">
          <NextImage
            className="rounded-lg shadow-xl object-cover object-top"
            src={transformImage(
              blok.image.filename,
              "1500x0/filters:quality(80)"
            )}
            alt="landing page feature screenshot"
            fill
            sizes="(max-width: 1300px) 100vw, 80vw"
          />
        </div>
      </div>
    </div>
  );
};

export default LandFeatureScreenshot;
