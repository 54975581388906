import { ReactNode } from "react";

type AuthModalLayoutProps = {
  title: string | ReactNode;
  description: string;
  children?: ReactNode;
};

export const AuthModalLayout = ({
  title,
  description,
  children,
}: AuthModalLayoutProps) => {
  return (
    <div>
      <div className="mt-5 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <div className="mt-2">
          <p className="text-md text-gray-500 tracking-normal">{description}</p>
        </div>
      </div>
      {!!children && <div className="mt-5 sm:mt-6">{children}</div>}
    </div>
  );
};
