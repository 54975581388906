import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import clsx from "clsx";

const Grid = ({ blok, className = undefined, style = undefined }) => (
  <ul
    {...storyblokEditable(blok)}
    key={blok._uid}
    className={clsx("flex py-8 mb-6 container mx-auto", className)}
    style={style}
  >
    {blok.columns.map((nestedBlok) => (
      <li key={nestedBlok._uid} className="flex-auto px-6">
        <StoryblokComponent blok={nestedBlok} />
      </li>
    ))}
  </ul>
);

export default Grid;
