import { Markdown } from "@crafthunt-ui/Markdown/Markdown";
import { EnvelopeIcon, UserIcon } from "@heroicons/react/24/outline";
import { FormikField } from "components/forms/generic/FormikField";
import { AuthFormDef } from "features/auth/types/auth.types";
import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { AuthModalLayout } from "../AuthModalLayout/AuthModalLayout";
import { FormSubmitButton } from "../FormSubmitButton/FormSubmitButton";

export const AuthSignUpForm = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<AuthFormDef>();

  return (
    <AuthModalLayout
      title={t("job-application-sign-up-title")}
      description={`🥷 ${t("job-application-sign-up-description")}`}
    >
      <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
        <div className="space-y-4 mb-3">
          {!values.isUsingEmail && (
            <FormikField
              id="auth_phone"
              name="phone"
              label="phone"
              type="phone"
              autoComplete="username tel"
            />
          )}
          {values.isUsingEmail && (
            <FormikField
              id="auth_email"
              name="email"
              label="email"
              type="email"
              autoComplete="username email"
              icon={EnvelopeIcon}
            />
          )}

          <FormikField
            id="auth_name"
            name="name"
            label="signup-name-label"
            placeholder={t("full-name-field-placeholder")}
            icon={UserIcon}
            autoComplete="name"
            autoFocus
          />
          <FormSubmitButton
            id="job-application-sign-up-continue"
            text={t("job-application-sign-up-continue")}
          />
          <div className="text-sm text-gray-400 sm:mt-4">
            <Markdown openLinksInNewTab>{t("auth-disclaimer")}</Markdown>
          </div>
        </div>
      </div>
    </AuthModalLayout>
  );
};
