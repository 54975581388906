import { useAuth } from "@auth/context/AuthContext";
import {
  AuthCTAButtonDef,
  EAuthCTAButtonType,
} from "@auth/types/auth-cta-button.types";
import clsx from "clsx";
import { AuthRoutesEnum } from "constants/routes.constants";
import { useAuthModal } from "features/auth/context/AuthModalContext";
import { EAuthFormType } from "features/auth/types/auth.types";
import { useTranslation } from "next-i18next";
import { Url } from "next/dist/shared/lib/router/router";
import Router, { useRouter } from "next/router";
import { MouseEvent } from "react";
import { triggerAnalyticsEvent } from "utils/analytics";

type AuthCTAButtonProps = {
  blok: AuthCTAButtonDef;
};

export const AuthCTAButton = ({ blok }: AuthCTAButtonProps) => {
  const router = useRouter();
  const { isLoggedIn } = useAuth();
  const { setAuthModalSettings } = useAuthModal();
  const { t, i18n } = useTranslation();

  const onClose = () => {
    triggerAnalyticsEvent("auth_cta_button_cancel", {
      buttonId: blok._uid,
      authType: blok.authType,
      journey: blok.journey,
      buttonType: blok.buttonType,
      url: router.asPath,
      language: i18n.language,
    });
  };

  const onSuccess = () => {
    triggerAnalyticsEvent("auth_cta_button_success", {
      buttonId: blok._uid,
      authType: blok.authType,
      journey: blok.journey,
      buttonType: blok.buttonType,
      url: router.asPath,
      language: i18n.language,
    });
  };

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    triggerAnalyticsEvent("auth_cta_button_click", {
      buttonId: blok._uid,
      authType: blok.authType,
      journey: blok.journey,
      buttonType: blok.buttonType,
      isLoggedIn,
      url: router.asPath,
      language: i18n.language,
    });
    if (isLoggedIn) {
      Router.push(AuthRoutesEnum.JOBS);
    } else {
      if (blok.journey) {
        const newUrl: Url = {
          pathname: router.pathname,
          query: {
            ...router.query,
            fromJourney: blok.journey,
          },
        };
        Router.replace(newUrl, newUrl, { scroll: false, shallow: true });
      }
      setAuthModalSettings({
        show: true,
        action: blok.authType || EAuthFormType.SIGN_UP,
        onClose,
        onSuccess,
      });
    }
  };

  return (
    <button
      id={blok._uid}
      className={clsx("not-prose inline-block", {
        "bg-primary-600 hover:bg-primary-700 text-white rounded-sm px-5 py-3 text-lg font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600":
          !blok.buttonType || blok.buttonType === EAuthCTAButtonType.PRIMARY,
        "text-sm font-semibold leading-6 hover:underline":
          blok.buttonType === EAuthCTAButtonType.SECONDARY,
      })}
      onClick={handleOpen}
    >
      {blok.text ||
        t(
          blok.authType === EAuthFormType.LOGIN
            ? "navigation-login"
            : "navigation-sign-up"
        )}
      {!!blok.withArrow && <span aria-hidden="true"> →</span>}
    </button>
  );
};
