import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";

type BaseAuthModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const BaseAuthModal = ({
  open,
  onClose,
  children,
}: BaseAuthModalProps) => {
  const cancelButtonRef = useRef(null);

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleOnClose();
      }
    };
    if (open) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [open, handleOnClose]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex items-start justify-center min-h-dvh pt-28 px-4 pb-40 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative inline-block w-full align-bottom bg-white rounded-lg px-4 py-8 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:p-14">
              <button
                type="button"
                className="absolute top-2 right-2 px-4 py-2 text-gray-700 hover:text-black"
                onClick={handleOnClose}
                ref={cancelButtonRef}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
              <div>{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
