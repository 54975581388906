type SectionHeaderProps = {
  label?: string;
  description?: string;
  content?: string;
};

export const SectionHeader = ({
  label,
  description,
  content,
}: SectionHeaderProps) => {
  return (
    <>
      {!!label && (
        <p className="text-base font-semibold tracking-wider text-primary-500 uppercase">
          {label}
        </p>
      )}
      <h2 className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        {description}
      </h2>
      {!!content && (
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500 whitespace-pre-line">
          {content}
        </p>
      )}
    </>
  );
};
