import clsx from "clsx";
import NextImage, { ImageProps as NextImageProps } from "next/image";
import { useState } from "react";
import { MdImageNotSupported } from "react-icons/md";
import { FirebaseImageSizeEnum, transformImage } from "utils/image.helper";
import { EmptyStateCompanies } from "../icons/empty-state/EmptyStateCompanies";
import { EmptyStateCraftsmen } from "../icons/empty-state/EmptyStateCraftsmen";

export enum ImageFallbackEnum {
  DEFAULT = "default",
  CRAFTSMAN = "craftsman",
  COMPANY = "company",
}

type ImageProps = {
  src?: string | null;
  storyblokOption?: string;
  firebaseImageSize?: FirebaseImageSizeEnum;
  fallbackType?: ImageFallbackEnum;
} & Omit<NextImageProps, "src" | "width" | "height" | "layout">;

export const Image = ({
  src,
  storyblokOption = "800x0/filters:quality(80)",
  firebaseImageSize = FirebaseImageSizeEnum.MEDIUM,
  className,
  fallbackType = ImageFallbackEnum.DEFAULT,
  onLoadingComplete,
  sizes,
  fill,
  priority,
  ...rest
}: ImageProps) => {
  const [error, setError] = useState(false);
  const [tryOriginalSrc, setTryOriginalSrc] = useState(false);

  const handleOnError = () => {
    if (tryOriginalSrc) {
      setError(true);
    } else {
      setTryOriginalSrc(true);
    }
  };

  if (!src || error) {
    return (
      <div
        className={clsx(className, "flex justify-center items-center")}
        {...rest}
      >
        {
          {
            [ImageFallbackEnum.CRAFTSMAN]: (
              <EmptyStateCraftsmen className="h-24 w-24" />
            ),
            [ImageFallbackEnum.COMPANY]: (
              <EmptyStateCompanies className="h-24 w-24" />
            ),
            [ImageFallbackEnum.DEFAULT]: (
              <MdImageNotSupported className="h-6 w-6" />
            ),
          }[fallbackType]
        }
      </div>
    );
  }
  return (
    <NextImage
      className={clsx(className)}
      src={
        tryOriginalSrc
          ? src
          : transformImage(src, storyblokOption, firebaseImageSize)
      }
      onError={handleOnError}
      onLoadingComplete={onLoadingComplete}
      sizes={sizes || "100vw"}
      fill={fill ?? true}
      priority={priority}
      {...rest}
    />
  );
};
