import { storyblokEditable } from "@storyblok/react";
import clsx from "clsx";
import NextImage from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { removeEmpty } from "utils/helpers";
import { FirebaseImageSizeEnum, transformImage } from "utils/image.helper";
import { getTranslatedSlug } from "utils/router.helper";

const SmallCompanyLogo = ({ src }) => {
  return (
    <NextImage
      className="object-contain object-left"
      src={transformImage(
        src,
        "400x0/filters:quality(80)",
        FirebaseImageSizeEnum.MEDIUM
      )}
      alt="Company logo"
      width={124}
      height={48}
      style={{
        maxWidth: "100%",
        height: "auto",
      }}
    />
  );
};

const LandTestimonial = ({
  blok,
  className = undefined,
  style = undefined,
}) => {
  const router = useRouter();
  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        "pb-16 bg-gradient-to-r from-primary-500 to-primary-600 lg:pb-0 lg:z-10 lg:relative",
        className
      )}
      style={style}
    >
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="relative lg:-my-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
          />
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
            <div className="aspect-w-10 aspect-h-8 sm:aspect-w-16 sm:aspect-h-12 lg:pb-0 lg:h-full">
              <NextImage
                className="object-cover rounded-xl shadow-xl"
                src={transformImage(
                  blok.image.filename,
                  "1500x0/filters:quality(80)"
                )}
                alt="Profile picture"
                fill
                sizes="(max-width: 1023px) 75vw, (max-width: 1800px) 30vw, 20vw"
              />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
            <blockquote>
              <div>
                <svg
                  className="h-12 w-12 text-white opacity-25"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="mt-6 text-2xl font-medium text-white">
                  {blok.quote}
                </p>
              </div>
              <footer className="mt-6">
                <p className="text-base font-medium text-white">
                  {blok.author}
                </p>
                <p className="text-base font-medium text-primary-100 hover:underline hover:text-primary-900">
                  {blok.profile_link?.url ? (
                    <Link
                      href={{
                        pathname: getTranslatedSlug(blok.profile_link),
                        query: removeEmpty({
                          ...router.query,
                          slug: undefined,
                        }),
                      }}
                    >
                      {blok.role}
                    </Link>
                  ) : (
                    blok.role
                  )}
                </p>
                {!!blok.company_logo?.filename && (
                  <div className="px-0 mx-0 py-4 sm:py-4">
                    {blok.profile_link?.url ? (
                      <Link
                        href={{
                          pathname: getTranslatedSlug(blok.profile_link),
                          query: removeEmpty({
                            ...router.query,
                            slug: undefined,
                          }),
                        }}
                      >
                        <SmallCompanyLogo src={blok.company_logo.filename} />
                      </Link>
                    ) : (
                      <SmallCompanyLogo src={blok.company_logo.filename} />
                    )}
                  </div>
                )}
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandTestimonial;
