import { array, object, string } from "yup";

/**
 * Form field error messages
 */
export const authErrorMessages = {
  name: "full-name-field-error",
  email: "form-field-email-invalid",
  phone: "form-field-phone-invalid",
  required: "form-field-required",
  role: "role-validation-error",
  trades: "trades-validation-error",
  password: "form-field-password-invalid",
};

export const AuthSignUpFormSchema = object().shape({
  email: string(),
  phone: string(),
  language: string(),
  password: string()
    .required(authErrorMessages.required)
    .min(6, authErrorMessages.password),
  role: object().shape({
    name: object().shape({
      name: string().required(authErrorMessages.role),
      id: string().required(authErrorMessages.role),
    }),
  }),
  trades: object().shape({
    data: array()
      .min(1, authErrorMessages.trades)
      .required(authErrorMessages.trades),
  }),
  address: object(),
});
