import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";

export const errorToast = (
  errorMessage: string,
  id: string = "form-error-toast"
) => {
  toast.error(errorMessage, {
    icon: <ExclamationCircleIcon className="h-6 w-6 text-red-500" />,
    id,
  });
};
