import { LoadScriptProps, useJsApiLoader } from "@react-google-maps/api";
import { ENV } from "constants/env";
import { MAP_ID } from "constants/google-maps.constants";
import { createContext, useContext } from "react";

type GoogleMapsContextDef = {
  isLoaded?: boolean;
};

const libs: LoadScriptProps["libraries"] = ["places", "geometry", "marker"];

const GoogleMapsContext = createContext<GoogleMapsContextDef | null>(null);

export function GoogleMapsProvider({ children }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: ENV.GOOGLE_MAPS_API_KEY,
    libraries: libs,
    language: "en",
    mapIds: [MAP_ID],
  });

  return (
    <GoogleMapsContext.Provider
      value={{
        isLoaded,
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
}

export function useGoogleMaps() {
  return useContext(GoogleMapsContext) as GoogleMapsContextDef;
}
