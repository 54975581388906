import clsx from "clsx";
import { AuthFormDef } from "features/auth/types/auth.types";
import { useFormikContext } from "formik";
import { CgSpinner } from "react-icons/cg";

type FormSubmitButtonProps = {
  id: string;
  text: string;
};

export const FormSubmitButton = ({ id, text }: FormSubmitButtonProps) => {
  const { isSubmitting } = useFormikContext<AuthFormDef>();
  return (
    <div className="mt-3 sm:mt-0">
      <button
        id={id}
        type="submit"
        className={clsx(
          "flex items-center justify-center w-full py-3 px-4 rounded-sm shadow bg-gradient-to-r from-primary-500 to-primary-600 text-white font-medium",
          !isSubmitting &&
            "hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 focus:ring-offset-gray-900"
        )}
        disabled={isSubmitting}
      >
        {isSubmitting ? <CgSpinner className="animate-spin h-6 w-6" /> : text}
      </button>
    </div>
  );
};
