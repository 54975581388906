import MagicLinkImg from "assets/magic_link.png";
import { useAuthModal } from "features/auth/context/AuthModalContext";
import { AuthFormDef, EAuthFormType } from "features/auth/types/auth.types";
import { interpolation } from "features/translations/helpers/translation.helper";
import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import NextImage from "next/image";
import { AuthModalLayout } from "../AuthModalLayout/AuthModalLayout";

export const AuthMagicLinkSent = () => {
  const {
    values: { isUsingEmail, email, phone },
  } = useFormikContext<AuthFormDef>();
  const {
    authModalSettings: { action },
  } = useAuthModal();
  const { t } = useTranslation();

  return (
    <AuthModalLayout
      title={
        <>
          <NextImage
            width={346}
            height={253}
            src={MagicLinkImg.src}
            alt="magic-link"
            className="mx-auto"
          />
          <div className="mt-8">
            {`${interpolation(t("job-application-login-title"), [
              isUsingEmail ? email : phone,
            ])} ✨`}
          </div>
        </>
      }
      description={t(
        action === EAuthFormType.APPLY_TO_JOB
          ? isUsingEmail
            ? "job-application-login-description-email"
            : "job-application-login-description-phone"
          : isUsingEmail
          ? "auth-login-magic-link-email"
          : "auth-login-magic-link-phone"
      )}
    />
  );
};
