import { EJourneyTypes } from "constants/journey.constants";
import { AuthRoutesEnum, RoutesEnum } from "constants/routes.constants";
import { useCandidate } from "context/CandidateContext";
import { useAuthModal } from "features/auth/context/AuthModalContext";
import { parseJwt } from "features/auth/helpers/auth.helper";
import { EAuthFormType } from "features/auth/types/auth.types";
import { Url } from "next/dist/shared/lib/router/router";
import Router, { useRouter } from "next/router";
import { useEffect } from "react";
import { getQueryParam, removeEmpty } from "utils/helpers";
import { AuthForm } from "./components/AuthForm/AuthForm";
import { BaseAuthModal } from "./components/BaseAuthModal/BaseAuthModal";

const REDIRECT_PATHS = {
  [EAuthFormType.LOGIN]: AuthRoutesEnum.JOBS,
  [EAuthFormType.SIGN_UP]: RoutesEnum.COMPLETE_SIGN_UP,
  [EAuthFormType.APPLY_TO_JOB]: AuthRoutesEnum.APPLY_TO_JOB,
};

export const AuthModal = () => {
  const { authModalSettings, setAuthModalSettings } = useAuthModal();
  const router = useRouter();
  const { jobAdData } = useCandidate();
  const queryFromJourney = getQueryParam(
    router.query.fromJourney
  )?.toLowerCase();
  const queryAuthModal = getQueryParam(router.query.auth_modal)?.toLowerCase();

  const handleOnClose = () => {
    if (queryAuthModal) {
      const newUrl: Url = {
        pathname: router.pathname,
        query: removeEmpty({
          ...router.query,
          auth_modal: undefined,
        }),
      };
      Router.replace(newUrl, newUrl, { scroll: false, shallow: true });
    }
    setAuthModalSettings({ ...authModalSettings, show: false });
    authModalSettings?.onClose?.();
  };

  const handleOnSuccess = async (
    action: EAuthFormType,
    accessToken?: string
  ) => {
    // find pathname based on whether auth form did a login or sign up
    let pathname: string = REDIRECT_PATHS[action];

    if (queryFromJourney === EJourneyTypes.BAUGPT) {
      pathname = RoutesEnum.BAUGPT;
    }
    // unless it is a company admin
    else if (accessToken && parseJwt(accessToken)?.isCrafthuntCompanyAdmin) {
      pathname = AuthRoutesEnum.MY_JOB_APPLICATIONS;
    }
    // or the modal action was applying to a job
    else if (authModalSettings.action === EAuthFormType.APPLY_TO_JOB) {
      pathname = REDIRECT_PATHS[authModalSettings.action];
    }
    await Router.push({
      pathname,
      query: removeEmpty({
        ...router.query,
        id: jobAdData?.id || undefined,
        slug: undefined,
        auth_modal: undefined,
      }),
    });
    setAuthModalSettings({ ...authModalSettings, show: false });
    authModalSettings?.onSuccess?.();
  };

  useEffect(() => {
    if (
      queryAuthModal &&
      Object.values(EAuthFormType).includes(queryAuthModal as EAuthFormType)
    ) {
      setAuthModalSettings({
        ...authModalSettings,
        show: true,
        action: queryAuthModal as EAuthFormType,
      });
    }
  }, [authModalSettings, queryAuthModal, setAuthModalSettings]);

  if (!authModalSettings) {
    return null;
  }

  return (
    <BaseAuthModal open={authModalSettings.show} onClose={handleOnClose}>
      <AuthForm onSuccess={handleOnSuccess} />
    </BaseAuthModal>
  );
};
