import { SectionHeader } from "@crafthunt-ui/SectionHeader";
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  EyeSlashIcon,
  HandThumbUpIcon,
  LightBulbIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { SmallFeature } from "./components/SmallFeature";

const LandFeatures = ({ blok, className = undefined, style = undefined }) => {
  const resolveIcon = {
    lightbulb: LightBulbIcon,
    eyeoff: EyeSlashIcon,
    thumbup: HandThumbUpIcon,
    swatch: SwatchIcon,
    chat: ChatBubbleLeftRightIcon,
    clipboard: ClipboardDocumentListIcon,
  };

  return (
    <div
      className={clsx("relative bg-white py-16 sm:py-24 lg:py-32", className)}
      style={style}
    >
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <SectionHeader
          label={blok.title}
          description={blok.description}
          content={blok.content}
        />
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {blok.features.map((feature) => (
              <SmallFeature
                key={feature.name}
                icon={resolveIcon[feature.icon]}
                name={feature.name}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandFeatures;
