import { functionsSetSession } from "@auth-session/api/auth.serverless.api";
import { login } from "@auth/api/auth.api";
import { AuthFormSchema } from "@auth/constants/auth-modal.form.validation";
import { useAuth } from "@auth/context/AuthContext";
import { signUpNewUser } from "@auth/helpers/auth-api.helper";
import { AuthFormDef, EAuthFormType } from "@auth/types/auth.types";
import { errorToast } from "@crafthunt-ui/ErrorToast/ErrorToast";
import axios from "axios";
import { EJourneyTypes } from "constants/journey.constants";
import { useCandidate } from "context/CandidateContext";
import { Form, Formik, FormikHelpers } from "formik";
import { useUtmParams } from "hooks/useUtmParams";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { ApiFormErrorDef } from "types/api-error.types";
import { triggerRequestMagicLink, triggerUserLogin } from "utils/analytics";
import { getQueryParam } from "utils/helpers";
import { AuthCredentialsForm } from "./components/AuthCredentialsForm/AuthCredentialsForm";
import { AuthMagicLinkSent } from "./components/AuthMagicLinkSent/AuthMagicLinkSent";
import { AuthSignUpForm } from "./components/AuthSignUpForm/AuthSignUpForm";

enum StepsEnum {
  CREDENTIALS,
  SIGN_UP,
  MAGIC_LINK,
}

const INITIAL_VALUES: AuthFormDef = {
  email: "",
  phone: "",
  language: "",
  password: "",
  name: "",
  isUsingEmail: false,
  isLoginWithPassword: false,
  isSignUp: false,
  forgotPassword: false,
};

type AuthFormProps = {
  onSuccess: (action: EAuthFormType, accessToken?: string) => Promise<void>;
};

export const AuthForm = ({ onSuccess }: AuthFormProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<StepsEnum>(StepsEnum.CREDENTIALS);
  const router = useRouter();
  const { loginAction } = useAuth();
  const { jobAdData } = useCandidate();
  const utmParams = useUtmParams();
  let fromJourney = getQueryParam(router.query.fromJourney) as
    | EJourneyTypes
    | undefined;
  fromJourney =
    fromJourney && Object.values(EJourneyTypes).includes(fromJourney)
      ? fromJourney
      : undefined;

  const loginOrBeginSignUp = async (
    values: AuthFormDef,
    formikHelpers: FormikHelpers<AuthFormDef>
  ) => {
    const email = values.isUsingEmail ? values.email : undefined;
    const phone = !values.isUsingEmail ? values.phone : undefined;
    const forgotPassword = !!values.forgotPassword;
    try {
      const response = await login({
        email,
        phone,
        password: forgotPassword ? undefined : values.password || undefined,
        jobAdId: jobAdData?.id,
        sendMagicLink: forgotPassword || undefined,
      });
      // if response contains message, then the user already exists without password
      if ("message" in response) {
        setStep(StepsEnum.MAGIC_LINK);
        triggerRequestMagicLink({
          email,
          phone,
          url: router.asPath,
          locale: router.locale,
          fromJourney,
        });
        formikHelpers.setSubmitting(false);
      } else {
        const { workerProfile, accessToken } = response;
        triggerUserLogin({
          email: workerProfile.email,
          phone: workerProfile.phone,
          url: router.asPath,
          locale: router.locale,
          fromJourney,
        });
        // Set session in middleware
        await functionsSetSession({ accessToken });
        // Update context to be logged in
        loginAction(workerProfile, accessToken);
        await onSuccess(EAuthFormType.LOGIN, accessToken);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.status === 404) {
          setStep(StepsEnum.SIGN_UP);
          formikHelpers.setFieldValue("isSignUp", true);
          formikHelpers.setFieldValue("password", undefined);
          formikHelpers.setFieldValue("isLoginWithPassword", false);
        } else if (err.response.status === 401) {
          // If isLoginWithPassword is already true,
          // then the password is incorrect
          if (values.isLoginWithPassword) {
            formikHelpers.setFieldError(
              "password",
              "form-field-password-invalid"
            );
            formikHelpers.setSubmitting(false);
          } else {
            // otherwise ask the user for password
            formikHelpers.setFieldValue("isLoginWithPassword", true);
            formikHelpers.setSubmitting(false);
          }
        } else {
          errorToast(t("unknown-error"));
          formikHelpers.setSubmitting(false);
        }
      } else {
        errorToast(t("unknown-error"));
        formikHelpers.setSubmitting(false);
      }
    }
  };

  const handleSignUpNewUser = async (
    values: AuthFormDef,
    formikHelpers: FormikHelpers<AuthFormDef>
  ) => {
    try {
      const { workerProfile, accessToken } = await signUpNewUser(
        values,
        router.locale,
        router.asPath,
        jobAdData,
        fromJourney,
        utmParams
      );
      // Update context to be logged in
      loginAction(workerProfile, accessToken);
      await onSuccess(EAuthFormType.SIGN_UP, accessToken);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if ((err as ApiFormErrorDef).response.data.errors?.length) {
          (err as ApiFormErrorDef).response.data.errors.forEach((apiError) => {
            formikHelpers.setFieldError(
              apiError.property,
              apiError.message.join(". ")
            );
          });
        } else if (err.response.status === 401) {
          errorToast(t("auth-sign-up-user-already-exists"));
          setStep(StepsEnum.CREDENTIALS);
          formikHelpers.setFieldValue("isSignUp", false);
          formikHelpers.setFieldValue("isLoginWithPassword", true);
          formikHelpers.setSubmitting(false);
        } else {
          errorToast(t("unknown-error"));
          formikHelpers.setSubmitting(false);
        }
      } else {
        errorToast(t("unknown-error"));
        formikHelpers.setSubmitting(false);
      }
    }
  };

  const handleSubmit = async (
    values: AuthFormDef,
    formikHelpers: FormikHelpers<AuthFormDef>
  ) => {
    formikHelpers.setSubmitting(true);
    if (step === StepsEnum.CREDENTIALS) {
      await loginOrBeginSignUp(values, formikHelpers);
    } else if (step === StepsEnum.SIGN_UP) {
      await handleSignUpNewUser(values, formikHelpers);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={AuthFormSchema}
      validateOnChange={false}
    >
      <Form id="auth-form">
        {
          {
            [StepsEnum.CREDENTIALS]: <AuthCredentialsForm />,
            [StepsEnum.SIGN_UP]: <AuthSignUpForm />,
            [StepsEnum.MAGIC_LINK]: <AuthMagicLinkSent />,
          }[step]
        }
      </Form>
    </Formik>
  );
};
