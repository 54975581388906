import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { FormikField } from "components/forms/generic/FormikField";
import { useAuthModal } from "features/auth/context/AuthModalContext";
import { AuthFormDef, EAuthFormType } from "features/auth/types/auth.types";
import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { triggerAnalyticsEvent } from "utils/analytics";
import { getQueryParam } from "utils/helpers";
import { convertLocaleForAnalytics } from "utils/language.helper";
import { AuthInputTransition } from "../AuthInputTransition/AuthInputTransition";
import { AuthModalLayout } from "../AuthModalLayout/AuthModalLayout";
import { AuthTextButton } from "../AuthTextButton/AuthTextButton";
import { FormSubmitButton } from "../FormSubmitButton/FormSubmitButton";
import { FormikPasswordField } from "../FormikPasswordField/FormikPasswordField";

const MODAL_TEXT = {
  [EAuthFormType.LOGIN]: {
    title: "auth-login-title",
    description: "auth-login-description",
    continueButtonText: "next-btn",
  },
  [EAuthFormType.SIGN_UP]: {
    title: "auth-sign-up-title",
    description: "auth-sign-up-description",
    continueButtonText: "next-btn",
  },
  [EAuthFormType.APPLY_TO_JOB]: {
    title: "signup-modal-title-company",
    description: "signup-modal-description-company",
    continueButtonText: "job-application-start-application",
  },
};

export const AuthCredentialsForm = () => {
  const { authModalSettings } = useAuthModal();
  const router = useRouter();
  const { t } = useTranslation();
  const { values, setFieldValue, submitForm } = useFormikContext<AuthFormDef>();
  const modalText =
    MODAL_TEXT[
      values.isLoginWithPassword
        ? EAuthFormType.LOGIN
        : authModalSettings.action
    ];

  useEffect(() => {
    setFieldValue("language", convertLocaleForAnalytics(router.locale));
  }, [router.locale, setFieldValue]);

  useEffect(() => {
    const use = getQueryParam(router.query?.use)?.toLowerCase();
    const email = getQueryParam(router.query?.email)?.toLowerCase();
    const phone = getQueryParam(router.query?.phone)?.toLowerCase();
    if (use === "email" || (email && !phone)) {
      setFieldValue("isUsingEmail", true);
    }
    if (email) {
      setFieldValue("email", email);
    }
    if (phone) {
      setFieldValue("phone", phone);
    }
  }, [
    router.query?.use,
    router.query?.email,
    router.query?.phone,
    setFieldValue,
  ]);

  const toggleIsUsingEmail = () => {
    setFieldValue("isUsingEmail", !values.isUsingEmail);
  };

  const handleForgotPassword = async () => {
    triggerAnalyticsEvent("forgot_password_button_click");
    await setFieldValue("forgotPassword", true);
    submitForm();
  };

  return (
    <AuthModalLayout
      title={t(modalText.title)}
      description={t(modalText.description)}
    >
      <div className="sm:max-w-xl sm:mx-auto lg:mx-0 space-y-4">
        {!values.isUsingEmail && (
          <FormikField
            id="auth_phone"
            name="phone"
            label="phone"
            type="phone"
            autoComplete="username tel"
          />
        )}
        {values.isUsingEmail && (
          <FormikField
            id="auth_email"
            name="email"
            label="email"
            type="email"
            autoComplete="username email"
            icon={EnvelopeIcon}
          />
        )}
        <AuthInputTransition show={values.isLoginWithPassword}>
          <FormikPasswordField
            id="auth_password"
            name="password"
            label="password-label"
            autoComplete="current-password"
          />
        </AuthInputTransition>
        <FormikField id="auth_language" name="language" type="hidden" />
        <FormSubmitButton
          id="auth_credentials-submit"
          text={t(modalText.continueButtonText)}
        />
        <AuthInputTransition
          show={!values.isLoginWithPassword}
          className="text-center"
        >
          <AuthTextButton
            id="toggle-is-using-email-button"
            onClick={toggleIsUsingEmail}
          >
            {values.isUsingEmail ? (
              <>
                <span>{t("auth-use-phone-instead")}</span>
                <PhoneIcon className="h-4 w-4 mb-0.5 inline" />
              </>
            ) : (
              <>
                <span>{t("auth-use-email-instead")}</span>
                <EnvelopeIcon className="h-4 w-4 mb-0.5 inline" />
              </>
            )}
          </AuthTextButton>
        </AuthInputTransition>
        <AuthInputTransition
          show={values.isLoginWithPassword}
          className="text-center"
        >
          <AuthTextButton
            id="forgot-password-button"
            onClick={handleForgotPassword}
          >
            {t("auth-forgot-password")}
          </AuthTextButton>
        </AuthInputTransition>
      </div>
    </AuthModalLayout>
  );
};
