import LanguageSelector from "@crafthunt-ui/LanguageSelector/LanguageSelector";
import TopBanner from "@crafthunt-ui/TopBanner";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Logo from "assets/crafthunt.svg";
import clsx from "clsx";
import { ENV } from "constants/env";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, ReactNode } from "react";
import { removeEmpty } from "utils/helpers";

export type NavigationItem = {
  id: string;
  link: string;
  label: string;
};

type BaseNavigationProps = {
  className?: string;
  leftMenu?: NavigationItem[];
  rightMenu?: ReactNode;
  hideHamburgerMenu?: boolean;
  disableLogoLink?: boolean;
} & (
  | {
      showLanguagePicker?: boolean;
      translatedSlugs?: TranslatedSlugDef[];
    }
  | {
      showLanguagePicker: boolean;
      translatedSlugs: TranslatedSlugDef[];
    }
) &
  (
    | {
        showLanguageDisclaimerBanner?: boolean;
        locale?: string;
      }
    | {
        showLanguageDisclaimerBanner: boolean;
        locale: string;
      }
  );

const DISCLAIMER_LANGUAGES = ["ro", "pl", "fr", "es", "it", "cs", "el"];

/** Also update the `_variables.scss` */
export const NAVIGATION_HEIGHT = 72;

export const BaseNavigation = ({
  leftMenu,
  rightMenu,
  showLanguagePicker,
  translatedSlugs,
  showLanguageDisclaimerBanner,
  locale,
  className,
  hideHamburgerMenu,
  disableLogoLink,
}: BaseNavigationProps) => {
  const router = useRouter();
  const { t } = useTranslation();

  const filteredQueriesForLink = removeEmpty({
    ...router.query,
    slug: undefined,
    step: undefined,
    email: undefined,
    phone: undefined,
    id: undefined,
    use: undefined,
    channelId: undefined,
    page: undefined,
  });

  return (
    <div className={clsx(className, "sticky top-0 z-50 shadow-md")}>
      <div className="relative">
        {showLanguageDisclaimerBanner &&
          locale &&
          DISCLAIMER_LANGUAGES.includes(locale) && (
            <TopBanner
              text={t("language-disclaimer")}
              textMobile={t("language-disclaimer-mobile")}
            />
          )}
        {ENV.MAINTENANCE === "true" && (
          <TopBanner text={t("maintenance-banner-title")} />
        )}
        <Popover as="header" className="relative">
          <div
            className="bg-gray-900 flex items-center"
            style={{ height: NAVIGATION_HEIGHT }}
          >
            <nav
              className="relative w-full max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  {disableLogoLink ? (
                    <Logo className="h-6 w-auto sm:h-7" />
                  ) : (
                    <Link
                      id="navigation-home"
                      href={{
                        pathname: "/",
                        query: filteredQueriesForLink,
                      }}
                    >
                      <span className="sr-only">Crafthunt</span>
                      <Logo className="h-6 w-auto sm:h-7" />
                    </Link>
                  )}
                  {!hideHamburgerMenu && (
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button
                        id="navigation-open-menu"
                        className="bg-gray-900 rounded-sm p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white"
                      >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  )}
                </div>
                {!!leftMenu?.length && (
                  <div className="hidden space-x-8 md:flex md:ml-10">
                    {leftMenu.map((item) => (
                      <Link
                        key={item.id}
                        id={item.id}
                        href={{
                          pathname: item.link,
                          query: filteredQueriesForLink,
                        }}
                        className="text-sm font-medium text-white hover:text-gray-300"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              {(!!rightMenu || showLanguagePicker) && (
                <div
                  className={clsx("z-10 md:flex md:items-center md:space-x-4", {
                    "hidden md:block": !hideHamburgerMenu,
                  })}
                >
                  {rightMenu}
                  {showLanguagePicker && (
                    <div
                      className={clsx({
                        "hidden md:block": !!rightMenu && hideHamburgerMenu,
                      })}
                    >
                      <LanguageSelector
                        translatedSlugs={translatedSlugs}
                        compressed
                      />
                    </div>
                  )}
                </div>
              )}
            </nav>
          </div>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute top-0 inset-x-0 p-2 transition origin-top md:hidden z-50"
            >
              {({ close }) => (
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <Logo className="h-8 w-auto" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button
                        id="navigation-close-menu"
                        className="bg-white rounded-sm p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600"
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="pt-5 pb-6">
                    {!!leftMenu?.length && (
                      <div className="px-2 space-y-2">
                        {leftMenu.map((item) => (
                          <Link
                            key={item.id}
                            id={item.id}
                            href={{
                              pathname: item.link,
                              query: filteredQueriesForLink,
                            }}
                            className="flex items-center justify-between px-3 py-2 rounded-sm text-base font-medium text-gray-900 hover:bg-gray-50"
                            onClick={() => close()}
                          >
                            {item.label}
                            <ChevronRightIcon className="size-5" />
                          </Link>
                        ))}
                      </div>
                    )}
                    {!!rightMenu && (
                      <div className="flex flex-col sm:block border-t border-b my-2 py-4 px-2 space-y-3 sm:space-y-0">
                        {rightMenu}
                      </div>
                    )}
                    {showLanguagePicker && (
                      <div className="flex justify-end mt-4 px-5 z-10">
                        <LanguageSelector translatedSlugs={translatedSlugs} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
};
