import { Optional } from "./helpers.types";

export enum EExternalLink {
  DOMAIN = "domain",
  YOUTUBE = "youtube",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  LINKEDIN = "linkedin",
  TIKTOK = "tiktok",
  KUNUNU = "kununu",
  XING = "xing",
}

export type ExternalLinkDef = {
  id: string;
  order: number | null;
  type: EExternalLink;
  value: string;
};

export type CreateEditExternalLinkDef = Optional<
  ExternalLinkDef,
  "id" | "order"
>;
