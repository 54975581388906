import { JobQuestionDef } from "@job-questions/types/job-questions.types";
import { EventProperties } from "@segment/analytics-next";
import { isDev, isProdOrStaging } from "constants/env";
import { EJourneyTypes } from "constants/journey.constants";
import {
  AuthUserDef,
  GeoSearchAddressDef,
} from "features/auth/types/auth.types";
import { posthog } from "posthog-js";
import { JobAdDef } from "types/job-ad.types";
import { LinkAnalyticsDef } from "types/links.type";
import { cookieEnabled, getCookie, removeDuplicates } from "./helpers";
import { convertLocaleForAnalytics } from "./language.helper";
import { fetchGeoData } from "./services/google-ip-geolocation.service";

export const UTM_PARAMS = [
  "utm_campaign",
  "utm_medium",
  "utm_source",
  "utm_content",
  "utm_term",
  "fbclid",
];

export const getAllUTMsByWindow = () => {
  if (typeof window === "undefined") return {};
  let searchQuery = decodeURIComponent(window.location.search.substring(1));
  if (!searchQuery) {
    // in case the params are not found, then try manually
    const splitUrl = window.location.href.split("?");
    if (splitUrl.length > 1) {
      searchQuery = decodeURIComponent(splitUrl[1]);
    }
  }
  const urlParams = searchQuery.split("&").map((x) => {
    const [key, value] = x.split("=");
    return { key, value };
  });
  const utmParams: Record<string, string> = {};
  for (const urlParam of urlParams) {
    if (UTM_PARAMS.includes(urlParam.key)) {
      utmParams[urlParam.key] = urlParam.value;
    }
  }
  return utmParams;
};

const getHubspotCookie = () => {
  if (cookieEnabled()) {
    return getCookie("hubspotutk");
  }
  return null;
};

const getFacebookBrowserIdCookie = () => {
  if (cookieEnabled()) {
    return getCookie("_fbp");
  }
  return null;
};
const getFacebookClickIdCookie = () => {
  if (cookieEnabled()) {
    return getCookie("_fbc");
  }
  return null;
};

export const triggerAnalyticsEvent = (
  event: string,
  data?: EventProperties
) => {
  const utmParams = getAllUTMsByWindow();
  const hubspot_hutk = getHubspotCookie();
  const fbp = getFacebookBrowserIdCookie();
  const fbc = getFacebookClickIdCookie();
  const analyticsData = {
    ...data,
    ...utmParams,
    hubspot_hutk,
    fbp,
    fbc,
  };

  // Segment
  window.analytics?.track(event, analyticsData);

  // Google Tag Manager
  window.dataLayer?.push({
    event,
    eventProps: analyticsData,
  });
};

export const triggerAnalyticsEventWithUserInfo = (
  event: string,
  user: AuthUserDef,
  data?: EventProperties
) => {
  return triggerAnalyticsEvent(event, {
    slug: user.slug,
    role: user.jobTitle?.name,
    trades: user.profileTrades,
    email: user.email,
    phone: user.phone,
    ...data,
  });
};

export const resetAnalytics = () => {
  if (isProdOrStaging) {
    window.analytics?.reset();
    posthog?.reset(true);
  }
};

export const triggerIdentify = async (
  userId: string,
  data: {
    email?: string;
    phone?: string;
    name?: string;
    fromJourney?: EJourneyTypes;
  }
) => {
  if (isDev) {
    return;
  }
  const utmParams = getAllUTMsByWindow();
  const hubspot_hutk = getHubspotCookie();

  const eventData = {
    ...(data.email && {
      email: data.email,
    }),
    ...(data.phone && {
      phone: data.phone,
    }),
    ...(data.name && {
      name: data.name,
    }),
    ...(data.fromJourney && {
      fromJourney: data.fromJourney,
    }),
    hubspot_hutk,
    ...utmParams,
  };

  if (
    window.analytics?.user?.().id?.() &&
    window.analytics?.user().id() !== userId
  ) {
    resetAnalytics();
  }

  window.analytics?.identify(userId, eventData);
  posthog?.identify(userId, eventData);
};

export const triggerEventSubmitCraftsmanForm = async (
  url: string,
  locale: string,
  data: {
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
    trades?: string[];
    role?: string;
    address?: GeoSearchAddressDef;
  }
) => {
  const { country, city, isInEurope } = await fetchGeoData();
  const domain = data.email?.split("@")[1] || "";

  // Track for Submit in Segment
  triggerAnalyticsEvent("craftsman_signup_completed", {
    url,
    domain,
    language: convertLocaleForAnalytics(locale),
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    trades: data.trades,
    role: data.role,
    address: data.address,
    country,
    city,
    isEu: isInEurope,
    type: "craftsman",
  });
};

export const triggerEventSubmitAuthSignUpForm = async (
  url: string,
  locale: string,
  data: {
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
    trades?: string[];
    role?: string;
    address?: GeoSearchAddressDef;
    fromJourney?: EJourneyTypes;
  }
) => {
  const { country, city, isInEurope } = await fetchGeoData();
  const domain = data.email?.split("@")[1] || "";

  // Track for Submit in Segment
  triggerAnalyticsEvent("account_signup_completed", {
    url,
    domain,
    language: convertLocaleForAnalytics(locale),
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    trades: data.trades,
    role: data.role,
    address: data.address,
    fromJourney: data.fromJourney,
    country,
    city,
    isEu: isInEurope,
  });
};

export const triggerIdentifyByEmail = async (email: string, locale: string) => {
  const { country, city, isInEurope } = await fetchGeoData();
  window.analytics?.identify(email, {
    language: convertLocaleForAnalytics(locale),
    type: "craftsman",
    email,
    country,
    city,
    isEu: isInEurope,
  });
};

/**
 * This is triggered when the users enters their email or phone before entering the sign up flow
 */
export const triggerFormSubmitNewsletter = async ({
  email,
  phone,
  name,
  url,
  locale,
  ...rest
}: {
  email?: string;
  phone?: string;
  name?: string;
  url: string;
  locale: string;
  [key: string]: string | boolean | null;
}) => {
  const { country, city, isInEurope } = await fetchGeoData();

  // Track for Submit in Segment
  triggerAnalyticsEvent("form_submit", {
    message: email,
    phone: phone,
    name: name,
    type: "craftsman",
    inputType: email ? "email" : "phone",
    url,
    language: convertLocaleForAnalytics(locale),
    country,
    city,
    isEu: isInEurope,
    ...rest,
  });

  // Custom Google Tag Manager Conversion
  window.dataLayer?.push({
    event: "signup",
    eventProps: {
      action: "signup",
      category: "newsletter",
      label: "email",
      value: email,
    },
  });
};

export const triggerUserLogin = ({
  email,
  phone,
  url,
  locale,
  fromJourney,
}: {
  email?: string;
  phone?: string;
  url: string;
  locale: string;
  fromJourney?: EJourneyTypes;
}) => {
  triggerAnalyticsEvent("user_login_web", {
    email,
    phone,
    url,
    language: convertLocaleForAnalytics(locale),
    fromJourney,
  });
};

/**
 * This is triggered when new users are creating an account with new soft login
 */
export const triggerUserCreated = async ({
  userId,
  name,
  email,
  phone,
  url,
  locale,
  jobAd,
  fromJourney,
}: {
  userId: string;
  name: string;
  email?: string;
  phone?: string;
  url: string;
  locale: string;
  jobAd?: JobAdDef;
  fromJourney?: EJourneyTypes;
}) => {
  name = name.trim();

  await triggerIdentify(userId, {
    email,
    phone,
    name,
  });

  const jobAdData = jobAd
    ? {
        jobId: jobAd.id,
        jobSlug: jobAd.fullSlug,
        jobTitle: jobAd.title_original || jobAd.title,
        jobStreet: jobAd.address.street,
        jobCity: jobAd.address.city_original || jobAd.address.city,
        jobCountry: jobAd.address.country_original || jobAd.address.country,
        jobRegion: jobAd.address.region,
        jobMunicipality: jobAd.address.municipality,
        jobRole: jobAd.role.name,
        jobTrade: jobAd.trade.name,
        jobDuration: jobAd.duration,
        jobStartDate: jobAd.jobStartDate,
        jobEndDate: jobAd.jobEndDate,
        jobProjectTypes:
          jobAd.projectTypes.map((project) => project.name).join(", ") || null,
        jobPublishedAt: jobAd.publishedAt,
        jobRequiredLanguages:
          jobAd.requiredLanguages.map((lang) => lang.value).join(", ") || null,
        jobSalary: !jobAd.salaryHidden
          ? [
              removeDuplicates(
                [jobAd.salaryMin, jobAd.salaryMax].filter(Boolean)
              ),
              jobAd.currency,
              jobAd.salaryFrequency,
            ].join(" ")
          : null,
        jobSalaryHidden: jobAd.salaryHidden,
        jobSalaryEstimated: jobAd.salaryEstimated,
        jobTravel: jobAd.travel,
        jobWorkingHours: jobAd.workingHours?.toString() || null,
        companyName: jobAd.crafthuntCompany.name,
        companyIsClaimed: jobAd.crafthuntCompany.isClaimed,
        companySlug: jobAd.crafthuntCompany.slug,
      }
    : {};

  // For backward compatibility we trigger this event
  triggerFormSubmitNewsletter({
    email,
    phone,
    url,
    locale,
    fromJourney,
    ...jobAdData,
  });

  const { country, city, isInEurope } = await fetchGeoData();

  const spaceIndex = name.indexOf(" ");
  let firstName = name;
  let lastName = "";
  // if name contains space, then we can split to first name and last name
  if (spaceIndex !== -1) {
    firstName = name.slice(0, spaceIndex).trim();
    lastName = name.slice(spaceIndex).trim();
  }

  triggerAnalyticsEvent("user_created_web", {
    email,
    phone,
    name,
    firstName,
    lastName,
    type: "craftsman",
    inputType: email ? "email" : "phone",
    url,
    language: convertLocaleForAnalytics(locale),
    country,
    city,
    isEu: isInEurope,
    ...jobAdData,
  });
};

/**
 * This is triggered when users are requesting magic link in new soft login
 */
export const triggerRequestMagicLink = async ({
  email,
  phone,
  url,
  locale,
  fromJourney,
}: {
  email?: string;
  phone?: string;
  url: string;
  locale: string;
  fromJourney?: EJourneyTypes;
}) => {
  const { country, city, isInEurope } = await fetchGeoData();

  // For backward compatibility we trigger this event
  triggerFormSubmitNewsletter({
    email,
    phone,
    url,
    locale,
  });

  triggerAnalyticsEvent("user_request_magic_link_web", {
    email,
    phone,
    type: "craftsman",
    inputType: email ? "email" : "phone",
    url,
    language: convertLocaleForAnalytics(locale),
    country,
    city,
    isEu: isInEurope,
    fromJourney,
  });
};

export const triggerJobApplicationScreeningQuestion = (
  data: {
    skipped: boolean;
    currentQuestionStep: number;
    totalQuestionSteps: number;
    jobAdId: string;
    language: string;
    numberOfOptions: number | undefined;
    numberOfSelectedOptions: number | undefined;
  } & Pick<JobQuestionDef, "type" | "answerType" | "answerChoice">
) => {
  triggerAnalyticsEvent("job_application_screening_question", {
    ...data,
    language: convertLocaleForAnalytics(data.language),
  });
};

/**
 * This is triggered when users are sending job applications
 */
export const triggerSendApplicationSuccess = async ({
  url,
  locale,
  user,
  companyId,
  jobAdId,
}: {
  url: string;
  locale: string;
  user: AuthUserDef;
  companyId: string;
  jobAdId?: string;
}) => {
  // For backward compatibility we trigger this event
  triggerEventSubmitCraftsmanForm(url, locale, {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    trades: user.profileTrades?.map((trade) => trade.name),
    role: user.jobTitle?.name,
    address: user.geoSearchAddress,
  });

  triggerAnalyticsEvent("send_application_success_web", {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    url,
    language: convertLocaleForAnalytics(locale),
    companyId,
    jobAdId,
  });
};

export const triggerUserNotAllowedToApply = ({
  requiredCountries,
  userCountryCode,
  jobAdId,
  isClickEvent,
}: {
  requiredCountries: string[];
  userCountryCode: string;
  jobAdId: string;
  isClickEvent: boolean;
}) => {
  triggerAnalyticsEvent(
    isClickEvent
      ? "user_not_allowed_country_click_to_job_finder"
      : "user_not_allowed_country",
    {
      requiredCountries: requiredCountries.join(", "),
      userCountryCode,
      jobAdId,
    }
  );
};

export const triggerUserSpeaksRequiredLanguage = (data: {
  requiredLanguages: string[];
  userLanguage: string;
  jobAdId: string;
}) => {
  triggerAnalyticsEvent("user_speaks_required_language", {
    ...data,
    requiredLanguages: data.requiredLanguages.join(", "),
  });
};

export const triggerUserNotAllowedToApplyLanguage = (data: {
  requiredLanguages: string[];
  userLanguage: string;
  jobAdId: string;
}) => {
  triggerAnalyticsEvent("user_not_allowed_language", {
    ...data,
    requiredLanguages: data.requiredLanguages.join(", "),
  });
};

export const triggerIpGeoLookupFailed = () => {
  triggerAnalyticsEvent("ip_geo_lookup_failed");
};

export const triggerUserUploadDocument = (data: {
  numberOfDocuments: number;
  hasAddedSocialLink: boolean;
  isStepRequired: boolean;
}) => {
  triggerAnalyticsEvent("user_upload_document_web", data);
};

export const triggerUserUploadDocumentSkip = () => {
  triggerAnalyticsEvent("user_upload_document_skip_web");
};

export const triggerWriteOnWhatsappClick = () => {
  triggerAnalyticsEvent("write_on_whatsapp_clicked_web");
};

export const triggerBookACallClick = () => {
  triggerAnalyticsEvent("book_a_call_clicked_web");
};

export const triggerGoToProfileFromJobs = () => {
  triggerAnalyticsEvent("jobs_empty_go_to_profile_web");
};

export const triggerShareBlog = (url: string) => {
  triggerAnalyticsEvent("blog_post_click_share", {
    url,
  });
};

export const triggerClickCTA = (data: LinkAnalyticsDef) => {
  triggerAnalyticsEvent("click_cta", data);
};

export const triggerClickViewOriginal = () => {
  triggerAnalyticsEvent("click_view_original");
};

export const triggerClickViewMore = () => {
  triggerAnalyticsEvent("click_view_more");
};
