import { isPossiblePhoneNumber } from "react-phone-number-input";

/** German phone numbers starting with these numbers are mobile numbers */
export const GERMAN_PHONE_MOBILE = ["15", "16", "17"];

export const isPhoneNumberInvalid = (value: string) => {
  if (
    !isPossiblePhoneNumber(value) ||
    (value.substring(0, 3) === "+49" &&
      !GERMAN_PHONE_MOBILE.includes(value.substring(3, 5)))
  ) {
    return true;
  }
  return false;
};

export const isEmailValid = (email) => {
  return !!String(email)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/);
};

export const isFormikFieldValid = <T extends string>(
  key: T,
  values: Record<T, string>,
  errors: Partial<Record<T, string>>
) => {
  return !!values[key] && !errors[key];
};
