import { isEmailValid, isPhoneNumberInvalid } from "utils/validation.helper";
import { ObjectSchema, boolean, object, string } from "yup";
import { AuthFormDef } from "../types/auth.types";
import { authErrorMessages } from "./auth-sign-up.form.validation";

export const AuthFormSchema: ObjectSchema<AuthFormDef> = object().shape({
  email: string().when("isUsingEmail", {
    is: (isUsingEmail) => isUsingEmail,
    then: (schema) =>
      schema
        .test("valid-email", authErrorMessages.email, (value) =>
          isEmailValid(value)
        )
        .required(authErrorMessages.required),
  }),
  phone: string().when("isUsingEmail", {
    is: (isUsingEmail) => !isUsingEmail,
    then: (schema) =>
      schema
        .test("valid-phone", authErrorMessages.phone, (value) => {
          if (value == null) {
            return true;
          }
          return !isPhoneNumberInvalid(value);
        })
        .required(authErrorMessages.required),
  }),
  language: string(),
  password: string().when(["isLoginWithPassword", "forgotPassword"], {
    is: (isLoginWithPassword, forgotPassword) =>
      isLoginWithPassword && !forgotPassword,
    then: (schema) => schema.required(authErrorMessages.required),
  }),
  isUsingEmail: boolean(),
  isLoginWithPassword: boolean(),
  isSignUp: boolean(),
  forgotPassword: boolean(),
  name: string().when("isSignUp", {
    is: (isSignUp) => isSignUp,
    then: (schema) =>
      schema
        .required(authErrorMessages.required)
        .test(
          "two-words",
          authErrorMessages.name,
          (value) => value.trim().split(" ").length > 1
        ),
  }),
});
