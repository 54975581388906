import { PhoneNumberInput } from "@crafthunt-ui/PhoneNumberInput/PhoneNumberInput";
import clsx from "clsx";
import { interpolation } from "features/translations/helpers/translation.helper";
import { ErrorMessage, useField } from "formik";
import { useTranslation } from "next-i18next";
import { ElementType, InputHTMLAttributes, ReactNode } from "react";

export type FormikFieldProps = {
  id: string;
  name: string;
  label?: string | ReactNode;
  type?: InputHTMLAttributes<HTMLInputElement>["type"] | "phone";
  unit?: string;
  icon?: ElementType;
  iconAfter?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  autoComplete?: string;
  helpText?: string;
  autoFocus?: boolean;
};

export const FormikField = ({
  id,
  name,
  label,
  unit,
  icon: Icon,
  iconAfter,
  type = "text",
  disabled,
  placeholder,
  className,
  autoComplete,
  helpText,
  autoFocus,
}: FormikFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();

  const inputClass = clsx(
    "flex w-full rounded-sm border-0 px-4 py-3 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6",
    "shadow-sm outline-none ring-1 ring-inset ring-gray-300 appearance-none",
    "focus:ring-2 focus:ring-inset focus:ring-primary-400",
    "focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-400",
    "bg-white text-gray-900",
    meta.error && "ring-red-500 ring-2"
  );

  return (
    <div className={clsx("min-w-0 flex-1 relative", className)}>
      {!!label && (
        <label
          htmlFor={id}
          className="absolute -top-2 z-10 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {typeof label === "string" ? t(label) : label}
        </label>
      )}
      <div className="relative">
        {!!Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        {type === "phone" ? (
          <PhoneNumberInput
            id={id}
            {...field}
            onChange={(value) => helpers.setValue(value)}
            className={clsx(inputClass, "h-12")}
            inputClassName="border-0 border-white"
            disabled={disabled}
            autoComplete={autoComplete}
          />
        ) : (
          <input
            id={id}
            type={type}
            {...field}
            className={clsx(inputClass, {
              "pl-10": !!Icon,
              "pr-10": !!iconAfter,
              "ring-red-500 ring-2": meta.error,
            })}
            placeholder={placeholder || t(`${label}-placeholder`)}
            disabled={disabled}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
          />
        )}
        {!!unit && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="unit-label">
              {t(unit)}
            </span>
          </div>
        )}
        {!!iconAfter && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer hover:opacity-75">
            {iconAfter}
          </div>
        )}
      </div>

      <ErrorMessage
        name={name}
        className="field-error"
        render={(
          msg: string | { msg: string; values: (string | number)[] }
        ) => {
          const isObject = typeof msg === "object";
          return (
            <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
              {isObject ? interpolation(t(msg.msg), msg.values) : t(msg)}
            </div>
          );
        }}
      />

      {!!helpText && (
        <div className="text-xs text-gray-600 px-3 py-2">{helpText}</div>
      )}
    </div>
  );
};
