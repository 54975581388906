import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

type AuthTextButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "type"
>;

export const AuthTextButton = ({ className, ...rest }: AuthTextButtonProps) => {
  return (
    <button
      type="button"
      className={clsx(
        "text-sm font-semibold leading-6 text-primary-500 hover:text-primary-700 space-x-1",
        className
      )}
      {...rest}
    />
  );
};
